<template>
  <div class="content">
    <div class="title">Список гостей</div>
    <app-cells position="start">
      <app-button @click="onAddButtonHandler" type="button" size="link">
        <img src="@/assets/img/add-icon.svg" alt="">
        Добавить гостя
      </app-button>
    </app-cells>
    <app-cells position="between">
      <app-input
        v-model="input_search"
        @input.native="onFilterSearch($event.target.value)"
        class="input--450"
        type="search"
        placeholder="Поиск (имя, телефон, почта)"
      />
    </app-cells>
    <app-table-head
      @update-limiter="onUpdateLimiter"
      :count="table.items.length"
      :page="pagination.page"
      :all="pagination.count"
    />
    <v-client-table
      @row-click="onRowClick"
      :data="table.items"
      :columns="table.columns"
      :options="table.options"
      ref="tableDefault"
      class="table-default table-default--dynamic"
    >
      <div slot="h__name" class="table-default__left">Имя</div>
      <div slot="name" slot-scope="props" class="table-default__left">
        {{ props.row.name }}
      </div>
      <div slot="phone" slot-scope="props">
        {{ props.row.phone || '-' }}
      </div>
      <div slot="email" slot-scope="props">
        {{ props.row.email || '-' }}
      </div>
      <div slot="last_visit" slot-scope="props">
        {{ props.row.last_visit ? normalizeDate(props.row.last_visit) : '-' }}
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="onClickPagination"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__item pagination__item--prev'"
      :next-class="'pagination__item pagination__item--next'"
      :next-link-class="'pagination__link pagination__link--next'"
      :prev-link-class="'pagination__link pagination__link--prev'"
    />
    <app-sidebar-right title="Добавление гостя" :class="{'sidebar-manage--full': show_sidebar}" @close-sidebar="show_sidebar = !show_sidebar">
      <form @submit.prevent="onCheckForm">
        <app-form-group label="Имя" label-for="name" required>
          <app-input
            v-model="form.name"
            placeholder="Введите имя гостя"
            id="name"
            :error="$v.form.name.$error"
          />
          <template #error>
            <div v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
          </template>
        </app-form-group>
        <app-form-group label="Телефон">
          <app-phone
            v-model="form.phone"
            placeholder="Введите телефон"
            ref="phonePicker"
            @paste.native.prevent
          />
        </app-form-group>
        <app-form-group label="Язык">
          <app-cells position="start">
            <app-radio
              v-for="language in languages"
              :key="language.id"
              v-model="form.language"
              :value="language.id"
              name="language"
              :label="language.name"
              :icon="language.icon"
            />
          </app-cells>
        </app-form-group>
        <app-form-group label="Электронная почта" label-for="email">
          <app-input
            v-model="form.email"
            placeholder="Введите электронную почту"
            id="email"
            :error="$v.form.email.$error"
          />
          <template #error>
            <div v-if="$v.form.email.$dirty && !$v.form.email.email">Неправильный формат электронной почты</div>
          </template>
        </app-form-group>
        <app-form-group>
          <app-checkbox
            v-model="form.send_notifications"
            label="Не отправлять письма на E-mail"
          />
        </app-form-group>
        <app-form-group label="Дата рождения" label-for="birthday">
          <app-input
            @change.native="$v.form.birthday.$touch()"
            v-model="form.birthday"
            v-mask="'99.99.9999'"
            placeholder="Введите дату рождения"
            id="birthday"
            :error="$v.form.birthday.$error"
          />
          <template #error>
            <div v-if="$v.form.birthday.$dirty && !$v.form.birthday.normalDateSpan">Неправильная дата рождения</div>
            <div v-if="$v.form.birthday.$dirty && !$v.form.birthday.underscorePresent">Неправильный формат даты</div>
          </template>
        </app-form-group>
        <app-form-group>
          <div class="switcher">
            <label class="switcher__label">
              <input type="checkbox" v-model="form.is_blacklisted">
              <span class="switcher__control">
                <span class="switcher__point"></span>
              </span>
              <span class="switcher__text">Черный список</span>
            </label>
          </div>
        </app-form-group>
        <app-form-group label-for="allergy" label="Аллергия">
          <app-textarea
            v-model="form.allergy"
            id="allergy"
          />
        </app-form-group>
        <app-form-group label-for="comment" label="Примечание">
          <app-textarea
            v-model="form.comment"
            id="comment"
          />
        </app-form-group>
        <app-cells>
          <app-button ref="submit" :disabled="$v.form.$error">Добавить</app-button>
        </app-cells>
      </form>
    </app-sidebar-right>
  </div>
</template>

<script>
import { getGuests, postGuest, getLanguages } from '@/http'
import { debounce } from 'lodash'
import { dateFormatting, validDate } from '@/helpers'
import { isWithinInterval, subDays } from 'date-fns'
import { required, email } from 'vuelidate/lib/validators'

const normalDateSpan = value => {
  if (!value) return true
  else {
    if (!value.includes('_') && validDate(value)) {
      return isWithinInterval(new Date(dateFormatting(value, 'normal-to-iso-small')), {
        start: new Date(1925, 0, 1),
        end: subDays(new Date(), 1)
      })
    } else return false
  }
}

const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'Guests',
  data() {
    return {
      table: {
        items: [],
        columns: ['id', 'name', 'phone', 'email', 'last_visit'],
        options: {
          headings: {
            id: 'ID',
            name: 'Имя',
            phone: 'Телефон',
            email: 'Электронная почта',
            last_visit: 'Последний визит',
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            noResults: 'Нет подходящих записей',
          },
          rowClassCallback: () => 'table-default__row',
          resizableColumns: false
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 1,
      },
      form: {},
      table_limit: 20,
      show_sidebar: false,
      input_search: '',
      languages: [],
    }
  },
  validations: {
    form: {
      name: { required },
      email: { email },
      birthday: { normalDateSpan, underscorePresent },
    }
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems(page = this.pagination.page, page_size = this.table_limit, search = this.input_search) {
      getGuests({ page, page_size, search }).then(res => {
        this.table.items = res.data.results
        this.pagination.pages = res.data.pages
        this.pagination.count = res.data.count
      })
    },
    onAddButtonHandler() {
      if (!this.languages.length) {
        getLanguages()
          .then(response => {
            this.languages = response.data
          })
      }
      this.form = {
        language: 'ru',
      }
      this.$refs.phonePicker._data.phone = ''
      this.$v.form.$reset()
      this.show_sidebar = true
    },
    onUpdateLimiter(data) {
      this.table_limit = data
      this.$refs.tableDefault.setLimit(this.table_limit)
      this.fetchItems(this.pagination.page, this.table_limit)
    },
    onFilterSearch(search) {
      this.pagination.page = 1
      this.searchFilter(search, this)
    },
    searchFilter: debounce((search, vm) => {
      vm.fetchItems(1, vm.table_limit, search)
    }, 350),
    onClickPagination(page) {
      this.fetchItems(page, this.table_limit)
    },
    onRowClick(row) {
      this.$router.push({ name: 'guest-detail', params: { id: row.row.id } })
    },
    normalizeDate(date) {
      return dateFormatting(date, 'iso-to-normal')
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание',
          text: 'Проверьте правильность заполнения полей формы'
        })
      } else {
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      postGuest(this.normalizeForm())
        .then(() => {
          this.$refs.submit.preload = false
          this.show_sidebar = false
          this.fetchItems()
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Гость добавлен'
          })
        })
        .catch(() => { this.$refs.submit.preload = false })
    },
    normalizeForm() {
      const normalizedForm = { ...this.form }
      normalizedForm.birthday = dateFormatting(normalizedForm.birthday, 'normal-to-iso-small')
      normalizedForm.send_notifications = !normalizedForm.send_notifications
      if (!normalizedForm.phone) normalizedForm.phone = null

      return normalizedForm
    }
  }
}
</script>
